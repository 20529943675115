import { oss_svg_image_domain_address } from '@/constants/oss'
import { baseCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import { Type } from '@/components/lazy-image'
import { t } from '@lingui/macro'

/** 获取合约跟单图片地址 */
export const getCopyTradingOssImageUrl = (name: string, hasTheme = false) => {
  const commonState = baseCommonStore.getState()
  let themeName = ''
  if (hasTheme) themeName = commonState.theme === ThemeEnum.dark ? '_black' : '_white'

  const bannerBgImage = `${oss_svg_image_domain_address}${name}${themeName}${Type.png}`
  return bannerBgImage
}

/**
 * 财务记录划转账户名称
 * @param accountName
 * @returns
 */
export const getFinancialRecordGroupName = accountName => {
  const replaceMap = {
    follower: t`features_copy_trading_settings_settings_form_index_lnc2lzgv_z`,
    taker: t`features_future_select_account_modal_index_coc1pyija8`,
  }
  return accountName?.replace(/follower|taker/g, matched => replaceMap[matched]) || ''
}
